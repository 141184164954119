import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, Button, Card, Form, Radio, Checkbox, message, Typography, Row, Col, Empty, Spin } from 'antd';
import "antd/dist/reset.css";
import '../styles/FormComponent.css';

const { Title } = Typography;

const FormComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [guestList, setGuestList] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [updatedGuestId, setUpdatedGuestId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchGuestList();
  }, []);

  useEffect(() => {
    if (updatedGuestId !== null) {
      fetchGuestList();
    }
  }, [updatedGuestId]);

  const fetchGuestList = async () => {
    setLoading(true);
    try {
      const spreadsheetId = "1pb9rtHvyrgx5yjI_R70NA1p1VKSRuK7z2wF0_domOUw";
      const sheetName = "1632664130";
      const apiKey = "AIzaSyDsEw19yi18FrXajfAAaPFwKHCD9QV94-4";

      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${apiKey}`
      );

      if (response.data && response.data.values) {
        const headers = response.data.values[0];
        const guests = response.data.values.slice(1).map(row => {
          let guest = {};
          headers.forEach((header, index) => {
            guest[header.toLowerCase()] = header.toLowerCase() === 'partymembers'
              ? row[index] ? row[index].split(',').map(member => member.trim()) : []
              : row[index];
          });
          return guest;
        });

        setGuestList(guests);
        if (updatedGuestId !== null) {
          setFilteredGuests(guests.filter(guest => guest.id === updatedGuestId));
        } else {
          setFilteredGuests(guests.filter(guest => !guest.timestamp));
        }
      }
    } catch (error) {
      console.error('Error fetching guest list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchClick = () => {
    if (searchQuery.trim().length < 3) {
      message.error('Search query must be at least 3 characters long');
      return;
    }

    setSearchPerformed(true);
    const query = searchQuery.toLowerCase();
    const filtered = guestList.filter(guest =>
      guest.name.toLowerCase().includes(query) ||
      guest.partymembers.some(member => member.toLowerCase().includes(query))
    );
    setFilteredGuests(filtered);
  };

  const handleGuestSelect = (guest) => {
    if (guest.timestamp && guest.id !== updatedGuestId) return;

    setSelectedGuest(guest);
    form.setFieldsValue({
      email: guest.email || '',
      phone: guest.phone || '',
      attendEvent1: '',
      attendEvent2: '',
      selectedPartyMembersEvent1: [],
      selectedPartyMembersEvent2: [],
    });
  };

  const handleBackClick = () => {
    setSelectedGuest(null);
    form.resetFields();
  };

  const isUKPhoneNumber = (phoneNumber) => {
    const ukPhoneRegex = /^(?:\+44|0)7\d{9}$/;
    return ukPhoneRegex.test(phoneNumber);
  };

  const handleSubmit = async (values) => {
    if (!values.attendEvent1 && !values.attendEvent2) {
      message.error('Please select whether you will attend at least one event');
      return;
    }

    if (!isUKPhoneNumber(values.phone)) {
      message.error('Please enter a valid UK phone number');
      return;
    }

    if (values.attendEvent1 === 'yes' && (!values.selectedPartyMembersEvent1 || values.selectedPartyMembersEvent1.length === 0)) {
      message.error('If attending the Church Ceremony, you must select at least one party member');
      return;
    }

    if (values.attendEvent2 === 'yes' && (!values.selectedPartyMembersEvent2 || values.selectedPartyMembersEvent2.length === 0)) {
      message.error('If attending the Wedding Reception, you must select at least one party member');
      return;
    }

    const timestamp = new Date().toLocaleString();
    setSubmitting(true);

    try {
      const updatedGuest = {
        id: selectedGuest.id,
        email: values.email,
        phone: values.phone,
        attendEvent1: values.attendEvent1,
        selectedPartyMembersEvent1: values.selectedPartyMembersEvent1 ? values.selectedPartyMembersEvent1.join(', ') : '',
        attendEvent2: values.attendEvent2,
        selectedPartyMembersEvent2: values.selectedPartyMembersEvent2 ? values.selectedPartyMembersEvent2.join(', ') : '',
        timestamp: timestamp
      };

      const scriptUrl = 'https://script.google.com/macros/s/AKfycbxhL-ny9l5bJS9GwKIIfKe8zJ7mfwIXl2ErrlG6hL6usez9pbYj-pdyXF5Ve0AqkAR8/exec';
      const response = await axios.post(scriptUrl, null, {
        params: updatedGuest
      });

      if (response.status === 200) {
        message.success('Form submitted successfully');
        setUpdatedGuestId(selectedGuest.id);
        setSearchQuery('');
        setSelectedGuest(null);
        form.resetFields();
      } else {
        throw new Error('Failed to update guest');
      }
    } catch (error) {
      console.error('Error updating guest:', error);
      message.error('Failed to submit form. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  const validatePartyMembersEvent1 = (_, value) => {
    if (form.getFieldValue('attendEvent1') === 'yes' && (!value || value.length === 0)) {
      return Promise.reject(new Error('Please select at least one party member for the Church Ceremony'));
    }
    return Promise.resolve();
  };

  const validatePartyMembersEvent2 = (_, value) => {
    if (form.getFieldValue('attendEvent2') === 'yes' && (!value || value.length === 0)) {
      return Promise.reject(new Error('Please select at least one party member for the Wedding Reception'));
    }
    return Promise.resolve();
  };

  const handleAttendChangeEvent1 = (e) => {
    const value = e.target.value;
    if (value === 'no') {
      form.setFieldsValue({ selectedPartyMembersEvent1: [] });
    }
    form.validateFields(['selectedPartyMembersEvent1']);
  };

  const handleAttendChangeEvent2 = (e) => {
    const value = e.target.value;
    if (value === 'no') {
      form.setFieldsValue({ selectedPartyMembersEvent2: [] });
    }
    form.validateFields(['selectedPartyMembersEvent2']);
  };

  return (
    <div className="container">
      <Input.Search
        className="search-box"
        value={searchQuery}
        onChange={handleSearchChange}
        onSearch={handleSearchClick}
        placeholder="Search for your name or your party members..."
        enterButton="Search"
        style={{ marginBottom: '20px' }}
      />
      {loading && <Spin size="large" />}
      {!selectedGuest && searchPerformed && !loading && (
        <>
          {filteredGuests.length > 0 ? (
            <Row gutter={[16, 16]}>
              {filteredGuests.map((guest) => (
                <Col key={guest.id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    className="guest-card"
                    title={guest.name + "'s Group"}
                    onClick={() => handleGuestSelect(guest)}
                    hoverable={!guest.timestamp || guest.id === updatedGuestId}
                    style={{
                      cursor: guest.timestamp && guest.id !== updatedGuestId ? 'not-allowed' : 'pointer',
                      backgroundColor: guest.timestamp && guest.id !== updatedGuestId ? '#f0f0f0' : '#fff'
                    }}
                  >
                    {guest.timestamp && guest.id !== updatedGuestId ? (
                      <div>RSVP Completed</div>
                    ) : (
                      <ul>
                        {guest.partymembers.map((member, index) => (
                          <li key={index}>{member}</li>
                        ))}
                      </ul>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty />
          )}
        </>
      )}
      {selectedGuest && (
        <div>
          <Button className="backButton" onClick={handleBackClick} style={{ marginBottom: '20px' }}>Back</Button>
          <Title level={2}>{selectedGuest.name + "'s Group"}</Title>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="rsvp-form"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: 'Please enter a valid UK phone number' }]}
            >
              <Input />
            </Form.Item>

            <p>Church Ceremony</p>
            <Form.Item label="Will Attend" name="attendEvent1" rules={[{ required: true, message: 'Please select whether you will attend the Church Ceremony' }]}>
              <Radio.Group onChange={handleAttendChangeEvent1}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Form.Item
                  label="Party Members Attending the Church Ceremony"
                  name="selectedPartyMembersEvent1"
                  rules={[{ validator: validatePartyMembersEvent1 }]}
                >
                  <Checkbox.Group disabled={form.getFieldValue('attendEvent1') === 'no'}>
                    {selectedGuest.partymembers && selectedGuest.partymembers.map((member) => (
                      <Checkbox key={member} value={member}>{member}</Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Form.Item>

            <p>Wedding Reception</p>
            <Form.Item label="Will Attend" name="attendEvent2" rules={[{ required: true, message: 'Please select whether you will attend the Wedding Reception' }]}>
              <Radio.Group onChange={handleAttendChangeEvent2}>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Form.Item
                  label="Party Members Attending the Wedding Reception"
                  name="selectedPartyMembersEvent2"
                  rules={[{ validator: validatePartyMembersEvent2 }]}
                >
                  <Checkbox.Group disabled={form.getFieldValue('attendEvent2') === 'no'}>
                    {selectedGuest.partymembers && selectedGuest.partymembers.map((member) => (
                      <Checkbox key={member} value={member}>{member}</Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting}>Submit</Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {updatedGuestId && (
        <Row gutter={[16, 16]}>
          {guestList.filter(guest => guest.id === updatedGuestId).map((guest) => (
            <Col key={guest.id} xs={24} sm={12} md={8} lg={6}>
              <Card
                className="updated-guest-card"
                title={guest.name}
                hoverable={false}
                style={{
                  backgroundColor: '#f0f0f0'
                }}
              >
                <div>RSVP Completed</div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default FormComponent;
