import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Carousel from "../Carousel";
import Button from "../Button";
import { Dark } from "../../styles/Themes";
import '../../App.css';

const About = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section id="about">
      <Container>
        <Box>
          <Carousel />
        </Box>
        <Box>
          <Title>We’re Getting Married!</Title>
          <SubText>Join Us, We hope you can make it!</SubText>
          <SubTextLight>
            Dear Guests, <br />
            Welcome to our wedding website!
            Everything you need to know about the big day is right at your
            fingertips. We look forward to celebrating with you! <br/>Please make sure you RSVP below<br />
            
          </SubTextLight>
          <SubTextLight>
          With love, <br/>Mathews & Antonia!
          </SubTextLight>
          <ButtonContainer>
            <ThemeProvider theme={Dark}>
              <div onClick={() => scrollTo("rsvp")}>
                <Button text="RSVP" />
              </div>
            </ThemeProvider>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #cbd2c5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 40em) {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 64em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-family: 'Bostonbrush';
  font-size: ${(props) => props.theme.fontTitle};
  text-transform: capitalize;
  color: black;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: black;
  align-self: flex-start;
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  text-transform: capitalize;
  color: black;
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: flex-start;
  margin: 1rem auto;

  @media (max-width: 40em) {
    width: 40%;
    margin-left: 30%;
    margin-right: 30%
    button {
      margin: 0 auto;
      align-self: center;
    }
  }
`;

export default About;
