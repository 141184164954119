import React from "react";
import styled from "styled-components";

import image from "../../assets/SectionBg-min.jpg";

const Showcase = () => {

  return (
    <Section id="showcase" style={{backgroundImage: `url(${image})`}}>
    
    </Section>
  );
};

// background-attachment: fixed;
const Section = styled.section`
  min-height: 80vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0,0,0,.23);
  background-blend-mode: multiply;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 40em) {
    background-position-x: -520px, center;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
  }

  & > *:first-child {
    animation-duration: 20s;

    @media (max-width: 30em) {
      animation-duration: 15s;
    }
  }

  & > *:last-child {
    animation-duration: 15s;

    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
`;

export default Showcase;
