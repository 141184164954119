import React from "react";
import styled from "styled-components";
import FooterBanner from "./FooterBanner";

const Footer = () => {
  return (
    <Section>
      <FooterBanner />
      <Container></Container>
      <Credits>
        <span>
          &copy;{new Date().getFullYear()}{" "}
          <a href="https://webflame.co.uk"> | Photography and Web Design - www.WebFlame.co.uk</a>
        </span>
      </Credits>
    </Section>
  );
};

const Credits = styled.div`
  width: 75%;
  margin: 0 auto 20px;
  color: grey;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;

    span {
      margin-bottom: 1rem;
    }
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;

const Section = styled.section`
  min-height: 60vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 48em) {
    min-height: 25vh;
  }
`;

export default Footer;
