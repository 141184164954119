import React from "react";
import styled from "styled-components";
import '../App.css';

const TypeWriterText = () => {
  return (
    <>
      <Title>
        Mathews & Antonia
      </Title>
      <Subtitle>09 . 11 . 24</Subtitle>
    </>
  );
};

// color: #EFAD49;
const Title = styled.h2`
  font-family: 'Bostonbrush';
  font-size: ${(props) => props.theme.fontTitle};
  font-weight: 500;
  width: 80%;
  color: white;
  align-self: flex-start;
  margin-top: 5rem;
  margin-left: -5rem;

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxxl};
    margin-left: -0.1rem;
    margin-top: -15rem;
    color: black;
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
    margin-top: -15rem;
    margin-left: -0.1rem;
  }

  @media (max-width: 40em) {
    width: 90%;
  }
`;

const Subtitle = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: white;
  margin-bottom: 1rem;
  font-weight: 500;
  width: 80%;
  align-self: flex-start;
  margin-left: -5rem;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    margin-left: -0.1rem;
    color: black;
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
    margin-left: -0.1rem;
    
  }
`;

export default TypeWriterText;
