import * as React from "react";
import styled from "styled-components";
// import CustomGoogleForms from "../../components/CustomGoogleForms";
import FormComponent from '../../components/FormComponent';
import '../../App.css';

const Rsvp = () => {
  return (
    <Section id="rsvp">
      <Title>RSVP</Title>
      {/* <p>Please RSVP by TODO. We hope to see you there!</p> */}
      <FormComponent />
      <Container>
      
      {/* <SearchGuest details={guestList}/> */}
        {/* <CustomGoogleForms /> */}
        <br/>
        {/* <p>
          *Note: We will be in contact if we are unable to accomadate your party
          size
        </p> */}
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 80vh;
  width: 100vw;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.body};
  position: relative;
  @media (max-width: 40em) {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
`;

// text-transform: capitalize;
const Title = styled.h1`
  font-family: 'Bostonbrush';
  font-size: ${(props) => props.theme.fontxxxl};
  
  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 35%;
  margin: 2rem 38%;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

export default Rsvp;
