import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../DrawSvg";
import '../../App.css';
import '../../styles/FormComponent.css';

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
          },
        }
      );
    });

    return () => {};
  }, []);

  return (
    <Section id="roadmap">
      <Title>Events</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          {/* <Item>&nbsp;</Item> */}
          
          <RoadmapItem
            addToRefs={addToRefs}
            title="Church Wedding"
            subtext="Starts at 12.00pm"
            subtext2="Address: Harrowden Road, Bedford, MK42 0SP"
            parking="30-40 Parking Spaces available"
            map='<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4899.466850664481!2d-0.45207945206055566!3d52.12097893783708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877b691df964e43%3A0x1266194feb02a5d3!2sChrist%20the%20King%20Church%20Hall!5e0!3m2!1sen!2suk!4v1723502510279!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
            />
          <RoadmapItem2
            addToRefs={addToRefs}
            title="Reception"
            subtext="Starts at 6:00pm"
            parking="*No box Gifts Please"
            subtext2="Address: 466-468, Uxbridge Rd, Hayes UB4 0SD"
            
            // map="RSVP By: 19th October 2024"
            
          />
        </Items>
      </Container>
    </Section>
    
  );
};

//Single Roadmap Item
const RoadmapItem = ({ title, subtext, subtext2, subtext3, map, parking, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <p></p>
          <SubTitle>{title}</SubTitle>
          <Text>Christ the King Church Hall</Text>
          <Text>{subtext}</Text>
          <Text>{subtext2}</Text>
          <Text>{parking}</Text>
          <iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4899.466850664481!2d-0.45207945206055566!3d52.12097893783708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877b691df964e43%3A0x1266194feb02a5d3!2sChrist%20the%20King%20Church%20Hall!5e0!3m2!1sen!2suk!4v1723502510279!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const RoadmapItem2 = ({ title, subtext, subtext2, map, parking, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle>{title}</SubTitle>
          <Text>Crystal Banqueting Hall (Hayes)</Text>
          <Text>{subtext}</Text>
          <Text>{subtext2}</Text>
          <Text2>{parking}</Text2>
          <iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.6948834573755!2d-0.4140011110893896!3d51.518813499145296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766d10a4486c7b%3A0x387ce02b2edac52f!2sCrystal%20Banqueting%20Hall%20(Hayes)!5e0!3m2!1sen!2suk!4v1723503295707!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameBorder="0"></iframe>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.body};
  position: relative;
  @media (max-width: 40em) {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-y: hidden;
  }
`;

const Iframe = styled.iframe`
    width: 600px;
    height: 450px;
`;

  // text-transform: capitalize;
const Title = styled.h1`
  font-family: 'Bostonbrush';
  font-size: ${(props) => props.theme.fontTitle};

  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 70%;
  height: 200vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;

        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }

    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContainer = styled.div`
  width: 1140%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: #cbd2c5;
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  font-weight: 400;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const Text2 = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  font-weight: 600;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const Image = styled.span`
  height: '100vh';
  width: '100%';
`;

export default Roadmap;
