import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";
import '../../App.css';

const Faqs = () => {
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "top top",
      bottom: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: true,
    });
    return () => {
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section id="faqs">
      <Title>FAQ's</Title>
      <Container>
        <Box>
        <Accordion title="I can't find my name/party to RSVP?">
           If you cannot find your name or party, please contact us for further assistance.
          </Accordion>
          <Accordion title="Is there a dress code?">
          Yes, there is a dress code for the church wedding, which is pastel colours. For the reception, there is no specific dress code, so feel free to wear any colours.
          </Accordion>
          <Accordion title="Is there available parking?">
          Yes, there are around thirty parking spaces available for the church wedding. The reception venue also has plenty of parking space available.
          </Accordion>
          <Accordion title="Are children allowed?">
          Yes, children are allowed.
          </Accordion>
        </Box>
        <Box>
          <Accordion
            title="How do I RSVP?">
            You can RSVP on this website.
          </Accordion>
          <Accordion
            title="What time should I arrive?">
            For the church wedding, please arrive before 11:50 am, as the church mass starts at noon. For the wedding reception, please arrive at 6:00 pm.
          </Accordion>
          <Accordion
            title="Can I bring a plus one?">
            If you are attending as a family, then no, but it depends on the circumstances. Please contact us for further information.
          </Accordion>
          <Accordion
            title="Where should I go for accommodation?">
            You can find information about hotels on this website.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 75vh;
  height: auto;
  width: 100vw;
  background-color: #aab3a2;
  color: ${(props) => props.theme.body};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 40em) {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
`;

// text-transform: uppercase;
const Title = styled.h1`
  font-family: 'Bostonbrush';
  font-size: ${(props) => props.theme.fontxxxl};
  
  font-weight: 500;
  color: ${(props) => props.theme.body};
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.body};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *::last-child {
      & > *::first-child {
        margin-top: 0;
      }
    }
  }
`;

const Box = styled.div`
  width: 45%;

  @media (max-width: 64em) {
    width: 90%;
    align-self: center;
  }
`;

export default Faqs;
