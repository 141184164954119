import React from "react";
import styled from "styled-components";
import TypeWriterText from "../TypeWriterText";
import RoundTextBlack from "../../assets/Rounded-Text-Black.png";
import { keyframes } from "styled-components";
import image from "../../assets/LandingPage-min.jpg"
import useSound from 'use-sound'
import mySound from '../../assets/music/sound.mp3';

const Home = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const [playSound] = useSound(mySound, {
    interrupt: true,
  });

  const random = (id) => {
    scrollTo(id);
    playSound();
  }


  return (
    <Section id="home" style={{backgroundImage: `url(${image})`}}>
      <div className="circle">
      <Container>
        <Box>
          <TypeWriterText />
        </Box>
        <Box>
          {/* <CoverVideo /> */}
        </Box>

        <RoundText onClick={() => random("about")}>
          <Circle>&#x2193;</Circle>
          <img src={RoundTextBlack} alt="" />
        </RoundText>
      </Container>
      </div>
    </Section>
  );
};



// background-color: rgba(0,0,0,.3);
//   background-blend-mode: multiply;
// background-attachment: fixed;
const Section = styled.section`
  min-height: ${(props) => `calc(111vh - ${props.theme.navHeight})`};
  width: 100vw;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;

  background-size:cover;
  @media (max-width: 40em) {
    width: 100%;
    height: 100dvh;
    margin: 0px;
    padding: 0px;
    overflow: hidden; 
  }
`;

const Container = styled.div`
  width: 75%;
  min-height: 80vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }

  @media (max-width: 48em) {
    flex-direction: column-reverse;
    width: 50%;

    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  100%{
    transform: rotate(1turn);
  }
`;

const RoundText = styled.div`
  position: absolute;
  left: 47%;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    animation: ${rotate} 6s linear infinite reverse;
  }

    &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
    left: 80%;
    right: 2rem;
    bottom: 100%;
  }

  @media (max-width: 48em) {
    position: absolute;
    left: 37%;
    bottom: 4rem;
    width: 6rem;
    height: 6rem;
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 50%;

  }

  @media (max-width: 40em) {
    right: 1rem;
  }  
`;

const Circle = styled.span`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontxl};

  @media (max-width: 64em) {
    width: 2rem;
    height: 2rem;
    font-size: ${(props) => props.theme.fontlg};
  }

  @media (max-width: 48em) {
    right: 1rem;
  }
`;

export default Home;
